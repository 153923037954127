<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="">
    <main
      class="max-w-7xl w-full mx-auto px-4 sm:px-6 xl:rounded-2xl lg:px-8 bg-white mt-9"
    >
      <!-- <div class="flex-shrink-0 pt-16">
        <img class="mx-auto h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark.svg?color=indigo&shade=600" alt="Workflow" />
      </div> -->
      <div class="max-w-xl mx-auto py-8 md:py-16 lg:py-16 xl:py-16">
        <div class="text-center">
          <p
            class="text-sm font-semibold text-indigo-600 uppercase tracking-wide"
          >
            404 error
          </p>
          <h1
            class="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight md:text-4xl lg:text-4xl xl:text-4xl"
          >
            This page does not exist.
          </h1>
          <p class="mt-2 text-lg text-gray-500">
            The page you are looking for could not be found.
          </p>
        </div>
        <div class="mt-12">
          <h2
            class="text-sm font-semibold text-gray-500 tracking-wide uppercase"
          >
            Popular pages
          </h2>
          <ul
            role="list"
            class="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200"
          >
            <li
              v-for="(link, linkIdx) in links"
              :key="linkIdx"
              class="relative py-6 flex items-start space-x-4"
            >
              <div class="flex-shrink-0">
                <span
                  class="flex items-center justify-center h-12 w-12 rounded-lg bg-indigo-50"
                >
                  <component
                    :is="link.icon"
                    class="h-6 w-6 text-indigo-700"
                    aria-hidden="true"
                  />
                </span>
              </div>
              <div class="min-w-0 flex-1">
                <h3 class="text-base font-medium text-gray-900">
                  <span
                    class="rounded-sm focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                  >
                    <router-link :to="link.route" class="focus:outline-none">
                      <span class="absolute inset-0" aria-hidden="true" />
                      {{ link.title }}
                    </router-link>
                  </span>
                </h3>
                <p class="text-base text-gray-500">{{ link.description }}</p>
              </div>
              <div class="flex-shrink-0 self-center">
                <ChevronRightIcon
                  class="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </li>
          </ul>
          <div class="mt-8">
            <router-link
              to="/"
              class="text-base font-medium text-indigo-600 hover:text-indigo-500"
              >Or go back home<span aria-hidden="true">
                &rarr;</span
              ></router-link
            >
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup>
import { ChevronRightIcon } from "@heroicons/vue/solid";
import {
  OfficeBuildingIcon,
  FilmIcon,
  UsersIcon,
} from "@heroicons/vue/outline";

const links = [
  {
    title: "People",
    description: "Find new people",
    icon: UsersIcon,
    route: "/people",
  },
  {
    title: "Places",
    description: "Explore new places with our Places section",
    icon: OfficeBuildingIcon,
    route: "/places",
  },
  {
    title: "Productions",
    description: "View our latest productions",
    icon: FilmIcon,
    route: "/productions",
  },
];
</script>
